import {RegulationCheck, RegulationCheckDialog, TextLink} from "@beesset/upor-client-module-cmn";
import {useTranslation} from "react-i18next";
import {List} from "@beesset/ui-components";
import {Typography} from "@mui/material";
import React from "react";

const RegulationDialog = ({open = false, onClose, onAccept}) => {
    const {t} = useTranslation();

    return <RegulationCheckDialog
        title={t("components.signUp.regulation.content.title")}
        open={open}
        onClose={onClose}
        onAccept={onAccept}
    >
        <List
            sx={{
                pt: 0
            }}
            descriptors={[{
                isHeader: true,
                disableSticky: false,
                sx: {
                    color: "text.primary",
                    fontSize: "1rem"
                },
                name: t("components.signUp.regulation.content.subtitle1")
            }, {
                Component: <Typography fontSize="0.95rem">
                    {t("components.signUp.regulation.content.body1")}
                </Typography>
            }, {
                isHeader: true,
                disableSticky: false,
                sx: {
                    color: "text.primary",
                    fontSize: "1rem"
                },
                name: t("components.signUp.regulation.content.subtitle2")
            }, {
                Component: <ol>
                    <Typography component={"li"} fontSize="0.95rem">
                        {t("components.signUp.regulation.content.body2")}
                    </Typography>
                    <Typography component={"li"} fontSize="0.95rem">
                        {t("components.signUp.regulation.content.body3")}
                    </Typography>
                    <Typography component={"li"} fontSize="0.95rem">
                        {t("components.signUp.regulation.content.body4")}
                    </Typography>
                    <Typography component={"li"} fontSize="0.95rem">
                        {t("components.signUp.regulation.content.body5")}
                    </Typography>
                </ol>
            }]}
        />
    </RegulationCheckDialog>
}

const RegulationComponent = React.forwardRef((props, ref) => {
    const {t} = useTranslation();
    const [showRegulation, setShowRegulation] = React.useState(false);

    return <React.Fragment>
        <RegulationCheck
            description={<div style={{display: "inline-block"}}>
                <Typography variant="caption">
                    {` ${t("components.signUp.regulation.intro.part1")}`}
                </Typography>
                <TextLink
                    onClick={(e) => {
                        setShowRegulation(true);
                        e.stopPropagation();
                        e.preventDefault();
                        return false;
                    }}
                >
                    {t("components.signUp.regulation.intro.part2")}
                </TextLink>
                <Typography variant="caption">
                    {` ${t("components.signUp.regulation.intro.part3")}`}
                </Typography>
                <TextLink
                    onClick={(e) => {
                        setShowRegulation(true);
                        e.stopPropagation();
                        e.preventDefault();
                        return false;
                    }}
                >
                    {t("components.signUp.regulation.intro.part4")}
                </TextLink>
            </div>}
            warning={t("components.application.regulationWarning")}
            ref={ref}
        />
        <RegulationDialog
            open={showRegulation}
            onClose={() => setShowRegulation(false)}
            onAccept={() => {
                ref.current.mark();
                setShowRegulation(false);
            }}
        />
    </React.Fragment>
});

export default RegulationComponent;