import {Help, Info} from "@mui/icons-material";
import {ClientModuleInitializer, LayoutComponentId, ModuleConfig} from "@beesset/upor-client-module-cmn";
import {HelpComponent, LogoComponent, ValidatorsComponent} from "common";
import React from "react";
import RegulationComponent from "./components/signInUp/RegulationComponent";
import SignInLogo from "./components/signInUp/SignInLogo";

function App() {
    const config = React.useMemo(() => {
        let moduleConfig = new ModuleConfig();

        moduleConfig.ConfigProviderProps = {
            LogoComponent: LogoComponent,
            protectedResources: false
        }

        moduleConfig.ThemeProps = {
            primary: "#CC0000",
            secondary: "#C30A00"
        }

        moduleConfig.LayoutProps = {
            components: [
                {
                    id: "help",
                    Icon: Help,
                    link: "/help",
                    path: "/help/*",
                    component: HelpComponent,
                    order: 5,
                },
                {
                    id: "validators",
                    Icon: Info,
                    link: "/validators",
                    path: "/validators/*",
                    component: ValidatorsComponent,
                    order: 6
                }
            ],
            componentDefaultProps: {
                [LayoutComponentId.SIGN_IN]: {
                    componentProps: {
                        LogoComponent: SignInLogo
                    }
                },
                [LayoutComponentId.ARTICLES]: {
                    disabled: true
                },
                [LayoutComponentId.SIGN_UP]: {
                    componentProps: {
                        showPhoneField: false,
                        RegulationCheckComponent: RegulationComponent
                    }
                },
                [LayoutComponentId.APPLICATIONS]: {
                    disabled: true
                }
            }
        }
        return moduleConfig;
    }, []);

    return <ClientModuleInitializer {...config}/>;
}

export default App;
