import {useTheme} from "@mui/material";
import Logo from "./Logo.svg";
import LogoContrast from "./Logo_contrast.svg";
import {useTranslation} from "react-i18next";

const SignInLogo = () => {
    const {t} = useTranslation();
    const theme = useTheme();

    return <img
        src={theme.palette.mode === "light" ? Logo : LogoContrast}
        alt={t("components.signIn.logoAltText")}
        style={{
            maxWidth: 180
        }}
    />;
}

export default SignInLogo;